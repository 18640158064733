var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CartApi } from '@common/api/cart';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useCollection } from '@common/application/hooks/use-collection';
import { useUpdateCartCount } from '@common/application/store/cart';
import { productList } from '@common/application/store/product-list';
import { emitCacheCartNumber } from '@common/application/utils/cart-number';
import { classNames } from '@common/application/utils/classnames';
import { getProductCartCount } from '@common/application/utils/common';
import { createPath } from '@common/application/utils/create-path';
import { useCartNumberListener, usePopStateListener } from '@common/application/utils/event';
import LocalStorage from '@common/application/utils/storage';
import { Toast } from '@common/application/utils/ui/toast';
import { ErrorPanel } from '@common/components/common/error-panel';
import { AhaaGoodsCard } from '@common/components/common/product-card-ahaa';
import { LivingGoodsCard } from '@common/components/common/product-card-living';
import { POP_STATET, SCROLL_DISTANCE } from '@common/constants/common';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useAsyncEffect } from 'ahooks';
import { isNumber } from 'lodash-es';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useContext, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { RenderTypeContext } from '../context/render-type';
export const NotProduct = ({ image }) => {
    const { t } = useTranslation(['product-list']);
    return _jsx(ErrorPanel, { className: "mt-0", icon: image, content: t('noTips'), destroyButtons: true });
};
NotProduct.defaultProps = {};
export const ProductList = ({ mobile = false }) => {
    const [products, setProducts] = useRecoilState(productList);
    const updateCartCount = useUpdateCartCount();
    const { t } = useTranslation();
    const { collectionHandle } = useCollection();
    const { pc } = useBreakpoint();
    const { renderType } = useContext(RenderTypeContext);
    const showProducts = useMemo(() => {
        if (mobile) {
            return (products === null || products === void 0 ? void 0 : products.result) || [];
        }
        return products.webResult;
    }, [products]);
    useAsyncEffect(emitCacheCartNumber, []);
    useCartNumberListener((countStatistics) => {
        setProducts((goods) => {
            const webResult = goods.webResult.map((item) => {
                return Object.assign(Object.assign({}, item), { cartQuantity: getProductCartCount(item, countStatistics) });
            });
            return Object.assign(Object.assign({}, goods), { webResult });
        });
    });
    function addCart(goods, count, callBack) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield CartApi.add(Object.assign(Object.assign({}, goods), { number: isNumber(count) ? count : 1 }));
            updateCartCount();
            if (!res) {
                if (callBack)
                    callBack();
                Toast(t('product-list:addCart'), { type: 'success' });
            }
        });
    }
    const goToDetail = (goods) => {
        LocalStorage.set(SCROLL_DISTANCE, window.scrollY);
        Router.push(createPath(ROUTE_MAP.PRODUCT_DETAIL, {
            name: goods.cskuName,
            csku: goods.csku
        }, { withLocale: true }));
    };
    /**
     * 等待PLP数据加载完成之后，触发滚动
     */
    usePopStateListener(() => {
        /**
         * 目标元素element
         */
        const distance = LocalStorage.get(SCROLL_DISTANCE);
        const popState = LocalStorage.get(POP_STATET);
        if (popState && distance > 0 && pc) {
            window.scrollTo({ top: distance, behavior: 'smooth' });
            LocalStorage.set(POP_STATET, false);
            LocalStorage.set(SCROLL_DISTANCE, 0);
        }
    });
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: classNames('grid', 'gap-2 grid-cols-2', 'pc:gap-3 pc:grid-cols-5') }, { children: showProducts.map((goods, index) => {
                return renderType === 'ahaa' ? (_jsx(AhaaGoodsCard, { index: index, goods: goods, sizes: {
                        pc: {
                            width: 190,
                            height: 190
                        },
                        mobile: {
                            width: 171,
                            height: 171
                        }
                    }, onAddCart: (count, callBack) => addCart(goods, count, callBack), onLink: (goods) => goToDetail(goods), onCollect: (favorite, csku) => collectionHandle(favorite, csku) }, goods.csku)) : (_jsx(LivingGoodsCard, { index: index, goods: goods, sizes: {
                        pc: {
                            width: 190,
                            height: 190
                        },
                        mobile: {
                            width: 171,
                            height: 171
                        }
                    }, onAddCart: (count, callBack) => addCart(goods, count, callBack), onLink: (goods) => goToDetail(goods), onCollect: (favorite, csku) => collectionHandle(favorite, csku) }, goods.csku));
            }) })) }));
};
