import { classNames } from '@common/application/utils/classnames';
import { safeElement } from '@common/application/utils/safe-element';
import { map } from '@common/application/utils/transform/map';
import { Button } from '@common/components/common/button';
import { DImage } from '@common/components/common/image';
import { InputNumber } from '@common/components/common/input-number';
import { Price } from '@common/components/common/price';
import { useCart } from '@common/components/hook/header/use-cart';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Trigger from 'rc-trigger';

import { ICON_EMPTY_CART } from '@/resources/images';
import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

const CartEmptyBoundary: React.FC<
  React.PropsWithChildren<{ isEmpty?: boolean; contentText?: string }>
> = ({ isEmpty, children, contentText }) => {
  return isEmpty ? (
    <div className="flex flex-col justify-center items-center w-full py-6">
      <DImage src={ICON_EMPTY_CART} className="w-40 h-26" />
      <p className="text-black-3">{contentText || ''}</p>
    </div>
  ) : (
    safeElement(children)
  );
};

interface Props {
  dark?: boolean;
}

export const Cart: React.FC<Props> = ({ dark }) => {
  const router = useRouter();
  const { t } = useTranslation(['common']);

  const { mobile, visible, cartCount, disabled, count, setCartCount, addCart, hanldChange } =
    useCart();

  return (
    <>
      {!mobile ? (
        <Trigger
          popupVisible={visible}
          onPopupVisibleChange={hanldChange}
          action={['hover']}
          popup={
            <div className="bg-white w-105 shadow-xsm">
              <CartEmptyBoundary isEmpty={isEmpty(cartCount)} contentText={t('miniCart.content')}>
                <ul className="max-h-110.75 overflow-y-auto">
                  {cartCount &&
                    cartCount.map((item, index) => (
                      <li
                        className="py-4 px-4 flex"
                        key={item.id}
                        data-testid={`test-add-mini-cart-${index}`}
                      >
                        <div
                          className={classNames('flex mr-6 cursor-pointer')}
                          onClick={() => {
                            map.common.events.onLink({
                              linkType: 'product',
                              linkParams: { name: item.name, csku: item.csku, id: item.csku }
                            });
                          }}
                        >
                          <DImage
                            src={item.pic}
                            className={classNames('w-17.5 h-17.5 mr-3')}
                            fill
                          />
                          <div className={classNames('flex flex-col justify-between')}>
                            <p className="line-clamp-2 text-sm text-black-1 w-47">{item.name}</p>
                            <Price
                              price={item.salePrice}
                              priceClassName="font-medium text-black-1 text-sm"
                            />
                          </div>
                        </div>
                        <InputNumber
                          disabled={disabled}
                          min={1}
                          max={99}
                          className="pc:w-22"
                          btnClassName="pc:w-6"
                          value={!isEmpty(cartCount) ? cartCount[index][index] : 0}
                          onChange={(val) => {
                            setCartCount((value) => {
                              if (value) value[index][index] = val;
                              return value;
                            });
                            addCart(item, val);
                          }}
                        />
                      </li>
                    ))}
                </ul>
              </CartEmptyBoundary>
              <div className={classNames('px-4 py-5 shadow-sm-no-x')}>
                <Button
                  className={classNames('w-full h-12')}
                  onClick={() => {
                    router.push(ROUTE_MAP.CART);
                  }}
                >
                  {t('miniCart.view')}
                </Button>
              </div>
            </div>
          }
          popupAlign={{
            points: ['tr', 'br'],
            offset: [0, 24]
          }}
        >
          <Link
            data-testid="test-cart"
            className="w-6 h-6 pc:w-9 pc:h-9 relative grid place-items-center cursor-pointer"
            href={ROUTE_MAP.CART}
          >
            {dark ? (
              <BlackLineIcon className="w-full h-full" icon="cart" />
            ) : (
              <WhiteLineIcon className="w-full h-full" icon="cart" />
            )}
            <div
              className={classNames(
                'absolute -right-1.5 -top-1  grid place-items-center min-w-4 h-4 text-xs text-white bg-primary rounded-full',
                { hidden: !count }
              )}
            >
              {count}
            </div>
          </Link>
        </Trigger>
      ) : (
        <Link
          className="w-7 h-7 pc:w-11 pc:h-11 relative grid place-items-center"
          href={ROUTE_MAP.CART}
        >
          {dark ? (
            <BlackLineIcon className="w-full h-full" icon="cart" />
          ) : (
            <WhiteLineIcon className="w-full h-full" icon="cart" />
          )}
          <div
            className={classNames(
              'absolute flex items-center justify-center min-w-3.5 h-3.5 text-white bg-primary rounded-full',
              'text-3xs -top-1 -right-1',
              'pc:text-xs pc:-right-1.5 pc:-top-1.5 ',
              { hidden: !count }
            )}
          >
            {count}
          </div>
        </Link>
      )}
    </>
  );
};
