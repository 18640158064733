import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import { useToggle } from 'ahooks';
import { useEffect, useRef } from 'react';

import { GoodsCategory } from '@/components/common/goods-category';
import { IMG_MENU } from '@/resources/images';

export const Category = (props: any) => {
  const [open, openActions] = useToggle();
  const timer = useRef<NodeJS.Timer | null>(null);

  const triggerEl = useRef<HTMLDivElement | null>(null);

  const closeCategory = () => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => openActions.set(false), 500);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const top = props?.parentTriggerEl?.current?.getBoundingClientRect().bottom || 0;
  return (
    <div
      className="text-base w-10 h-10 categoryHover"
      onMouseEnter={() => {
        if (timer.current) clearTimeout(timer.current);
        openActions.set(true);
      }}
      onMouseLeave={closeCategory}
    >
      <div
        data-testid="test-category-menu"
        ref={triggerEl}
        className={classNames('w-10 h-10 flex just-start items-center text-black cursor-pointer')}
      >
        <DImage src={IMG_MENU}></DImage>
      </div>
      <GoodsCategory open={open} top={top} onHide={closeCategory} />
    </div>
  );
};
