import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const PayIcon = SpritesImage.createIcon({
  itemWidth: 120,
  itemHeight: 84,
  images,
  icons: {
    alipay: 1,
    ame: 2,
    mostercard: 3,
    octpay: 4,
    payme: 5,
    unionpay: 6,
    visa: 7,
    wechatpay: 8,
    banktransfer: 9
  }
});
