import { jsx as _jsx } from "react/jsx-runtime";
import Link from 'next/link';
/**
 * href为空时不让跳转
 */
const LinkOrDiv = ({ children, href, className }) => {
    if (href)
        return (_jsx(Link, Object.assign({ href: href, className: className }, { children: children })));
    return _jsx("div", Object.assign({ className: className }, { children: children }));
};
export default LinkOrDiv;
