import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useClientValue } from '@common/application/hooks/use-client-value';
import useLongHover from '@common/application/hooks/use-long-hover';
import { useScrollLock } from '@common/application/hooks/use-scroll-lock';
import { pageHomeCategory } from '@common/application/store/category';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { usePdpBtnFixedListener } from '@common/application/utils/event';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import { useEventListener } from 'ahooks';
import Router from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

import { FirstCategory } from './first-category';

interface Props {
  open: boolean;
  top: number;
  onHide: () => void;
}

const FirstCategoryItem = ({
  item,
  activeCategory,
  onHover,
  onLongHover,
  onClick,
  index
}: {
  item: ICategory;
  activeCategory?: ICategory;
  onHover: (item: ICategory) => void;
  onLongHover: (item: ICategory) => void;
  onClick: (item: ICategory) => void;
  index: number;
}) => {
  const elRef = useRef(null);

  useLongHover(() => onLongHover(item), elRef);

  return (
    <li
      data-testid={`test-category-${index}`}
      key={item.id}
      ref={elRef}
      className={classNames(
        'relative h-12.5 flex items-center px-4 border-t first-line: border-ahaa-border-menu pad:px-5 cursor-pointer first:border-none',
        'text-center text-black-3 hover:text-primary',
        'pc:text-left pc:h-13.5 pc:bg-primary pc:text-white pc:hover:bg-white',
        {
          'text-primary font-medium bg-white': activeCategory?.id === item.id,
          'pc:bg-white pc:text-primary': activeCategory?.id === item.id
        }
      )}
      style={{ width: `calc(100%)` }}
      onMouseEnter={() => onHover(item)}
    >
      <a
        href={createPath(
          ROUTE_MAP.CATEGORY_LIST,
          {
            category: item.name,
            id: item.id
          },
          { withLocale: true }
        )}
        className={classNames('block w-full cursor-pointer', 'text-xsm', 'pc:text-base')}
        onClick={(e) => {
          e.preventDefault();
          onClick(item);
        }}
      >
        <div className="w-full h-full flex justify-between items-center">
          <div className="line-clamp-2">{item.name}</div>
          <WhiteLineIcon className={classNames('w-4 h-4 hidden', 'pc:block')} icon="arrow" />
        </div>
      </a>
    </li>
  );
};

const CATEGORY_HEIGHT = 600;
const DISTANCE_BOTTOM = 30;

export const GoodsCategory = (props: Props) => {
  const { categoryList: categoryData } = useRecoilValue(pageHomeCategory);
  const { open, top, onHide } = props;
  const [firstData, setFirstData] = useState<ICategory>();
  const [activeCategory, setActiveCategory] = useState<ICategory>();
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [pdpBtnHeight, setPdpBtnHeight] = useState<number>(0);

  const screens = useBreakpoint();
  const firstCatClick = async (data: ICategory) => {
    if (screens.mobile && data.childTree.length) return;
    onHide();
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: data.name,
        id: data.id
      })
    );
  };

  const setDefaultChecked = () => {
    if (!categoryData.length) return;
    setFirstData(categoryData[0]);
    setActiveCategory(categoryData[0]);
  };

  useEffect(() => {
    if (open && categoryData.length) {
      setDefaultChecked();
    }
  }, [open, categoryData]);

  const bodyEl = useClientValue(() => document.body);
  const [, toggle] = useScrollLock(bodyEl);

  useEffect(() => toggle(!!open), [open]);

  useEffect(() => {
    if (!open) {
      setFirstData(undefined);
      setActiveCategory(undefined);
    }
  }, [open]);

  usePdpBtnFixedListener(({ btnHeight }) => {
    setPdpBtnHeight(btnHeight);
  });

  useEventListener(
    'resize',
    () => {
      setViewportHeight(window.innerHeight);
    },
    { passive: true }
  );

  useEffect(() => {
    setViewportHeight(window.innerHeight);
  }, []);

  const categoryHeight = useMemo(() => {
    if (viewportHeight - top - pdpBtnHeight < CATEGORY_HEIGHT) {
      return `calc(100% - ${top + DISTANCE_BOTTOM + pdpBtnHeight}px)`;
    }
    return '600px';
  }, [viewportHeight, top, pdpBtnHeight]);

  return (
    <div
      className={classNames('fixed left-0 z-20 w-full', {
        hidden: !open
      })}
      style={{
        top,
        height: screens.pc ? categoryHeight : `calc(100% - ${top}px)`
      }}
    >
      <div className="pc:container pad:container h-full">
        <div
          className={classNames('grid grid-cols-12 gap-3 h-full relative pc:shadow-xsm bg-white')}
          onMouseLeave={() => {
            onHide();
          }}
        >
          <ul
            className={classNames(
              'overflow-y-auto overflow-x-hidden bg-black-8 relative h-full',
              'col-span-3',
              'pc:col-span-2 '
            )}
            onMouseLeave={() => {
              if (activeCategory?.id !== firstData?.id) {
                setActiveCategory(firstData);
              }
            }}
          >
            {categoryData.map((item, index) => (
              <FirstCategoryItem
                onClick={firstCatClick}
                key={item.id}
                item={item}
                activeCategory={activeCategory}
                onHover={(el) => {
                  setActiveCategory(el);
                }}
                onLongHover={(el) => {
                  setFirstData(el);
                }}
                index={index}
              />
            ))}
          </ul>
          {firstData && (
            <div
              className={classNames(
                '-ml-3 bg-white overflow-y-auto',
                'col-span-9',
                'pc:col-span-10'
              )}
            >
              <FirstCategory key={firstData.id} data={firstData.childTree} firstData={firstData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GoodsCategory.defaultProps = {
  open: true
};
